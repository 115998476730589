<template>
<div>
    <template v-if="!openProductDataError">
        <h1 :class="titleClass">{{ openProductData.name_ukr }} <span v-if="productInfoData.is_priceoff" class="font-weight-thin">(SN {{productInfoData.priceoff_sn}})</span></h1>
        <div class="caption mb-4">Код: <span class="ml-2"> {{ openProductData.typhoon_id }} </span></div>
    </template>
    <template v-if="productInfoData.typhoon_id">
        <div v-if="productInfoData.is_du" class="caption mb-4 mt-n4 blue--text font-italic">* Товар з дефектом упаковки. Не був у користуванні.</div>
    </template>
    <div v-if="productType[2]" class="caption mb-4 mt-n4 primary--text font-italic">* Товар продається за Серійним номером.</div>

</div>
</template>

<script>
export default {
    props: {
        titleClass: String
    },
    data(v) {
        return {}
    },
    computed: {
        productType() {
            return this.$route.params.id.split('_')
        },
        productInfoData() {
            return this.$store.state.catalog.productInfoData
        },
        openProductData() {
            return this.$store.state.catalog.openProductData
        },
        openProductDataError(){
            return this.$store.state.catalog.openProductDataError
        }
    }
}
</script>

<style>

</style>
